<template>
    <div v-loading="is_panding" class="benshlush-sales">
        <div class="benshlush-sale-wrapper">
            <div class="benshlush-sale-wrapper-title">
                <h2>בן שלוש - מבצעים</h2>
            </div>
            <div class="benshlush-sale-wrapper-tools">
                <el-input style="width: 30%;" v-model="search" placeholder="חיפוש מבצע..." />
                <a href="https://wa.me/?text=%D7%91%D7%9F%20%D7%A9%D7%9C%D7%95%D7%A9%20%D7%A7%D7%98%D7%9C%D7%95%D7%92%20%D7%9E%D7%91%D7%A6%D7%A2%D7%99%D7%9D%20http%3A%2F%2Ftinyurl.com%2Fben-shlush-catalog" target="_blank">
                    <div class="whatsapp-share-button">
                        <img :src="whatsapp" alt="שיתוף בווצאפ">
                    </div>
                </a>

            </div>
            <div class="benshlush-sale-wrapper-content">
                <h2 v-if="!filter_by_search.length">לא נמצאו מבצעים בהתאם לחיפוש שהוקלד!</h2>
                <template v-for="item in filter_by_search" :key="item.uid">
                    <ItemCard :item="item" />
                </template>
              
            </div>
            <div class="benshlush-sale-wrapper-footer">
                <el-button @click="handle_show_order_before_submit" type="success">תצוגת הזמנה</el-button>
                <!-- <el-button @click="show_order_before_submit" type="success">סיום הזמנה</el-button> -->
                <h1>סה"כ {{total_items.length}} פריטים נוספו לסל</h1>
            </div>
        </div>

        <OrderSummary
            v-if="show_order_summary"
            :items="total_items"
            :total_items="total_items.length"
            @close="show_order_summary=false;"
            @submit="handle_submit"
        />
    </div>
</template>

<script>
    import { computed, defineAsyncComponent, ref } from 'vue';
    import ItemCard from '../../components/BenShlush/client/ItemCard.vue'
    import {get_sales_form_db,price_per_item} from '../../components/BenShlush/scripts'
    import {slide_pop_error} from '../../Methods/Msgs'
    import { projectFunctions } from '../../firebase/config';
    import whatsapp from '../../components/BenShlush/icons/share.png'

    export default {
        components:{
            ItemCard,
            OrderSummary: defineAsyncComponent(() => import('./OrderSummary.vue'))
        },
        setup(){

            const search = ref('')
            const show_order_summary = ref(false)

            const sales = ref([])
            const is_panding = ref(false)

            const filter_by_search = computed(() => {
                if(!search.value) return sales.value
                return sales.value.filter(sale => sale.name.includes(search.value))
            })

            const total_items = computed(() => {
                return sales.value.filter((sale) => sale?.qnt > 0)
            })

            const handle_show_order_before_submit = () => {
                if(total_items.value.length == 0){
                    slide_pop_error('עליך להזין כמויות במוצרים ע"מ לסיים הזמנה!')
                }
                else{
                    show_order_summary.value = true
                }
            }

            const handle_submit = (user_data) => {
                try{
                        console.log(user_data);
                        console.log('1',total_items.value);

                        const html = `
                    <!DOCTYPE html>
                    <html dir="rtl" lang="he">
                    
                    <head>
                        <meta charset="UTF-8">
                        <title>אישור הזמנה</title>
                    </head>
                    
                    <body dir="rtl" style=" background: #fff; font-family: Arial, sans-serif; padding: 20px;">
                    
                        <!-- Company Details & Logo -->
                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                            <tr>
                                <!-- Company Details -->
                                <td style="text-align: right; vertical-align: top; width: 80%;">
                                    <h2 style="margin: 1px; padding: 0; font-weight:bold;">בן שלוש ייבוא וייצוא בע"מ</h2>
                                    
                                </td>
                                <!-- Company Logo -->
                                <td style="text-align: left; vertical-align: top; width: 20%;">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Fshlush_logo_375x375.jpg?alt=media&token=c33db170-87c8-44b6-ad6e-b55b9877c719" alt="Company Logo" style="max-width: 100px;">
                                </td>
                            </tr>
                        </table>
                        
                        <!-- Customer Details -->
                        <table width="100%" border="0" cellpadding="0" cellspacing="0" style="margin-top: 20px;">
                            <tr>
                                <!-- Customer Details -->
                                <td style="text-align: right; vertical-align: top; width: 80%;">
                                    <h3 style="margin: 1px; padding: 0; font-weight:bold;"> לכבוד</h3>
                                    <p style="margin: 1px; padding: 0; font-weight:bold;">${user_data.company}</p>
                                    <p style="margin: 1px; padding: 0; font-weight:bold;">שם המזמין: ${user_data.contact_name}</p>
                                    <p style="margin: 1px; padding: 0; font-weight:bold;"> ח.פ: ${user_data.company_id}</p>
                                    <p style="margin: 1px; padding: 0; font-weight:bold;"> טלפון: ${user_data.phone}</p>
                                    <p style="margin: 1px; padding: 0; font-weight:bold;">תאריך: ${new Date().toLocaleString('he-IL')}</p>
                                </td>
                            </tr>
                        </table>
                        <h2>הערה להזמנה: ${user_data.comment}</h2>
                        <!-- Products Table -->
                        <table  style="width: 100%; border-collapse: collapse; margin-top: 20px;">
                            <thead>
                                <tr>
                                    <th style="border: 1px solid black; padding: 10px;">תמונת פריט</th>
                                    <th style="border: 1px solid black; padding: 10px;">ברקוד פריט</th>
                                    <th style="border: 1px solid black; padding: 10px;">שם פריט</th>
                                    <th style="border: 1px solid black; padding: 10px;">מספר יחידות במארז</th>
                                    <th style="border: 1px solid black; padding: 10px;">כמות מארזים</th>
                                    <th style="border: 1px solid black; padding: 10px;">מחיר ליחידה</th>
                                    <th style="border: 1px solid black; padding: 10px;">סך מחיר</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Repeat this row for each payment -->
                                ${items_table_rows(total_items.value)}
                            </tbody>
                        </table>
                
                        <!-- Total Price -->
                        <div style="margin-top: 20px; text-align: right;">
                            <strong>
                            סה"כ מארזים: ${total_items.value.reduce((acc, curr) => {return acc + curr.qnt}, 0)}, סה"כ יח': ${total_items.value.reduce((acc, curr) => {return acc + Number(curr.qnt) * Number(curr.pac_amount)}, 0)}, סה"כ מחיר: ${total_items.value.reduce((acc, curr) => 
                                {return acc + (Number(curr.qnt) * Number(curr.pac_amount)) * price_per_item(curr)}, 0).toFixed(2)} &#8362;
                            </strong>
                        </div>`

                        /* 
                            ${((Number(item.qnt) * Number(item.pac_amount))*Number(item.unit_price)).toFixed(2)}}  }
                        */

                        sendEmail(html,user_data)
                        show_order_summary.value = false
                        clean_qnt_and_type()

                       
                }catch(err){
                    console.error(err.message)
                    slide_pop_error(err.message)
                }
                
                
            }

            function sendEmail(html,user_data) {
                /* 'yavrosman@gmail.com ', 'ebshloosh@gmail.com'*/
                projectFunctions.httpsCallable('send_email')({
                        html,
                        destinations:['mosescorcias@gmail.com',`${user_data.email}`],
                        from:"בן שלוש הזמנות",
                        title:"אישור הזמנה חדשה"
                }) 
            }

            const init = async() => {
                try{
                    is_panding.value = true
                    sales.value = await get_sales_form_db()
                    is_panding.value = false
                }catch(err){
                    is_panding.value = false
                    slide_pop_error(`חלה שגיאת בנתינת נתונים - ${err.message}`)
                }
            }

            const clean_qnt_and_type = () => {
                console.log('2');
                sales.value.forEach(item => {
                    item.qnt = 0
                })
            }

            function items_table_rows(items){
                let tableRows = "";
                for (let item of items) {
                    const calculatedPrice = price_per_item(item);
                    const isPriceDifferent = calculatedPrice !== Number(item.unit_price);
                    tableRows += `
                        <tr>
                            <td style="text-align: center; vertical-align: middle; border: 1px solid black; padding: 10px;"> <img src="${item.image}" style="width: 50px; height: auto;"></td>
                            <td style=" text-align: center; vertical-align: middle;border: 1px solid black; padding: 10px;">${item.barcode}</td>
                            <td style=" text-align: center; vertical-align: middle;border: 1px solid black; padding: 10px;">${item.name}</td>
                            <td style=" text-align: center; vertical-align: middle;border: 1px solid black; padding: 10px;">${item.pac_amount}</td>
                            <td style=" text-align: center; vertical-align: middle;border: 1px solid black; padding: 10px;">${item.qnt}</td>
                            <td style="text-align: center; vertical-align: middle;border: 1px solid black; padding: 10px;">${isPriceDifferent ? "<s>" + item.unit_price + " &#8362;"+"</s><br>" + calculatedPrice.toFixed(2) : Number(item.unit_price).toFixed(2)} &#8362;</td>
                            <td style=" text-align: center; vertical-align: middle;border: 1px solid black; padding: 10px;">${((Number(item.qnt) * Number(item.pac_amount)) * price_per_item(item)).toFixed(2)} &#8362;</td>
                        </tr>`;
                }
                return tableRows
            }

            init()

            return{
                sales,
                init,
                is_panding,
                search,
                filter_by_search,
                total_items,
                handle_show_order_before_submit,
                show_order_summary,
                handle_submit,
                clean_qnt_and_type,
                price_per_item,
                whatsapp
            }
        }
    }
</script>

<style scoped>
    .benshlush-sales{
        width: 100%;
        height: 100%;
        padding: 0 5px;
        overflow-y: auto;
    }
    .benshlush-sale-wrapper{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
    .benshlush-sale-wrapper-title{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .benshlush-sale-wrapper-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .benshlush-sale-wrapper-content{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 5px;
        padding: 5px;  
        /* Hide scrollbar for IE, Edge and Firefox */
         scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */

        /* overflow-y: scroll; */
    }
    .benshlush-sale-wrapper-content::-webkit-scrollbar {
        display: none;
    }
    @media only screen and (max-width: 600px){
        .benshlush-sale-wrapper-content{
            grid-template-columns: repeat(2,calc(100% / 2))
        }
    }
    .benshlush-sale-wrapper-footer{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        background: #fff;
        z-index: 1000;
    }
    @media only screen and (max-width: 600px){
        .benshlush-sale-wrapper-footer{
                font-size: 10px;
        }
    }
    .whatsapp-share-button {
        display: inline-block;
        width: 50px;
        height: 100%;
        color: white;
        border-radius: 50%;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        cursor: pointer;
    }
    
</style>